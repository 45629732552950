<template>
  <a-card title="我的售后提醒" :bodyStyle="{height: '410px'}">
    <a-table
        :columns="columns"
        :data-source="list"
        :loading="tableLoading"
        :pagination="pagination"
        rowKey="id"
        bordered
        @change="pageChange"
    >
      <template slot="maintenanceStatus" slot-scope="text">
        <span v-if="text === 0">待保养</span>
        <span v-if="text === 1">已保养</span>
      </template>
      <template slot="remindedTimes" slot-scope="text, record">
        <span>{{ record.timeoutRemindTimes  }}</span>/
        <span>{{ text }}</span>
      </template>
      <template slot="finishedMaintenanceTimes" slot-scope="text, record">
        <span>{{ record.timeountMaintenanceTimes }}</span>/
        <span>{{ text }}</span>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { getCenterAfterSale, getCenterProject } from '../../api/center/center'
import { mapState } from 'vuex'

export default {
  name: 'afterSale',
  data () {
    return {
      columns: [
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        {
          title: '项目名称',
          dataIndex: 'projectName'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName'
        },
        {
          title: '上次保养日期',
          dataIndex: 'lastMaintenanceDate'
        },
        {
          title: '下次保养日期',
          dataIndex: 'nextMaintenanceDate'
        },
        {
          title: '保养状态',
          dataIndex: 'maintenanceStatus',
          scopedSlots: { customRender: 'maintenanceStatus' }
        },
        {
          title: '应保养次数',
          dataIndex: 'maintenanceTimes'
        },
        {
          title: '超时提醒次数/已提醒次数',
          dataIndex: 'remindedTimes',
          scopedSlots: { customRender: 'remindedTimes' }
        },
        {
          title: '超时保养次数/已保养次数',
          dataIndex: 'finishedMaintenanceTimes',
          scopedSlots: { customRender: 'finishedMaintenanceTimes' }
        }
      ],
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 5
      },
      pagination: {
        total: 0,
        pageSize: 5
      },
      tableLoading: true,
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      getCenterAfterSale(this.filterForm).then(res => {
        this.list = res.data.list
        this.pagination.total = res.data.total
        this.tableLoading = false
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
